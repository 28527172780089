// the Uniswap Default token list lives here
// export const DEFAULT_TOKEN_LIST_URL = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
// console.log(DEFAULT_TOKEN_LIST_URL)
// export const DEFAULT_LIST_OF_LISTS: string[] = [
//   DEFAULT_TOKEN_LIST_URL
// ]

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://ulamchain.io/tokenlist.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
]