import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useActiveWeb3React } from '../hooks'
import { useWalletModalToggle } from '../state/application/hooks'
export default function RouterAuth({children}:any){
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  useEffect(()=>{
    if(!account) toggleWalletModal()
  },[children])
  if(account){
    return <>{children}</>
  }else{
    return <>
      <Redirect to="/swap" ></Redirect>
    </>
  }
}