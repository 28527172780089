import { useCallback, useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { addPopup, PopupContent, removePopup, toggleWalletModal, toggleSettingsMenu } from './actions'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../index'
import { useWeb3React } from '@web3-react/core'
export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useWalletModalOpen(): boolean {
  return useSelector((state: AppState) => state.application.walletModalOpen)
}

export function useWalletModalToggle(): () => void {
  const dispatch = useDispatch()
  NetworkSwitch()
  return useCallback(() => dispatch(toggleWalletModal()), [dispatch])
}
export async function NetworkSwitch(){
  const { chainId } = useWeb3React();
  const request = (window?.ethereum as any).request;
  // const chainId = await request({ method: "eth_chainId" });
  if(!chainId){
    try {
      await request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: '0x544b2fba' }],
      });
    } catch (e) {  
      console.log(e) 
      if (e.code === 4902) {
        await request({
          "method": "wallet_addEthereumChain",
          "params": [
            {
              "chainId": "0x544b2fba", // 目标链ID
              "chainName": "ULAM chain",
              "nativeCurrency": {
                "name": "ULAM",
                "symbol": "ULAM",
                "decimals": 18
              },
              "iconUrls": ["iipfs://Qmf8MKpC4qZYxfqTpPWPFutha7krNYEgEq4186SVBRDwHJ"],
              "rpcUrls": ["https://ulam2.0.ulamchain.io/rpc/"], // 节点
              "blockExplorerUrls": ["https://ulam2.0.ulamchain.io"]
            }
          ]
        })
      }
    }
  }
}
export function useSettingsMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.settingsMenuOpen)
}

export function useToggleSettingsMenu(): () => void {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(toggleSettingsMenu()), [dispatch])
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter(item => item.show), [list])
}
